@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";

.Report {
  list-style: none;
  background-color: $dark-mine-shaft;
}

.Text {
  margin: 0;
  white-space: nowrap;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  width: 200px;
}

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.Main {
  display: flex;
  padding: 16px 20px 10px;
  border-bottom: 1px solid #525252;
  align-items: center;
}

.Name {
  margin-left: 20px;
  flex: 1;
  min-width: 100px;
}

.Date {
  margin-right: 32px;
}

.MobileDetails {
  display: block;
}

.Details {
  display: flex;
  padding: 10px 20px 16px;
}

.DesktopDetails {
  display: none;
}

.Title {
  @include body3();

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 1em;
}

@include breakpoint(medium) {
  .Main {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
  }

  .Details {
    padding: 0;
  }

  .MobileDetails {
    display: none;
  }

  .DesktopDetails {
    display: grid;
    grid-template-columns: auto auto;
  }

  .Type {
    margin-right: 32px;
    width: 100px;
  }

  .Date,
  .Type {
    display: flex;
    flex-direction: column;
  }
}

@include breakpoint(large) {
  .Main {
    padding: 27px 27px 27px 53px;
  }

  .Title {
    @include body1();
  }

  .Text {
    @include body1();
  }

  .Date,
  .Type {
    margin-right: 83px;
  }
}
