@import "../../styles/colors";

.Heartbeat {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Status {
  &Container {
    position: relative;
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-color: transparent;
    color: transparent;

    &Connected {
      color: $success;
    }
    &Partial {
      color: $warning;
    }
    &Disconnected {
      color: $error;
    }
    &Error {
      color: $dove-grey;
    }
  }

  &Icon {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: currentColor;

    &DisconnectedError {
      position: absolute;
      color: $black;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &Pulse {
    border: 1px solid currentColor;
    border-radius: 30px;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;

    &Inner {
      animation: pulse-inner 2s linear infinite;
    }

    &Outer {
      animation: pulse-outer 2s linear infinite;
    }
  }
}

@keyframes pulse-inner {
  0% {
    transform: scale(0.8, 0.8);
  }
  40% {
    transform: scale(1.4, 1.4);
  }
  80% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(0.8, 0.8);
  }
}

@keyframes pulse-outer {
  0% {
    transform: scale(0.8, 0.8);
  }
  40% {
    transform: scale(1.8, 1.8);
  }
  80% {
    transform: scale(1.8, 1.8);
  }
  100% {
    transform: scale(0.8, 0.8);
  }
}
