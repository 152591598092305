@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/typography";

.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px $mine-shaft;
}

.Type {
  margin: 0 0 0 $x-small;
  text-transform: capitalize;
  color: $white;
}

.EventId {
  margin: 0 0 0 34px;
  color: $white;
}

.TitleGroup {
  padding: 10px 0;

  .Type,
  .EventId {
    align-self: baseline;
  }
}

.Icon {
  display: flex;
}

.Button {
  &:not(:last-child) {
    margin-right: 10px;
  }

  &Group {
    display: flex;
    flex-flow: row nowrap;
    padding: $x-small 0 $x-small $x-small;
    position: relative;
  }

  &Share {
    display: none;
  }
}

.ButtonMenu {
  background-color: $cod-gray;
  margin-right: $x-small;
  color: $white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.SecondaryButtons {
  display: none;
}

.TitleWrapper {
  display: flex;
  align-items: center;
}

@include breakpoint(medium) {
  .ButtonMenu {
    display: none;
  }
  .ButtonShare {
    display: flex;
  }
  .SecondaryButtons {
    display: flex;
    margin-right: $x-small;
  }
  .ButtonTracking {
    margin-right: $x-small;
  }
}

@include breakpoint(xlarge) {
  .Header {
    display: flex;
    padding: $x-small 0;
  }

  .TitleGroup {
    flex: 1 0 auto;
    flex-flow: row wrap;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .ButtonGroup {
    padding: 0;
  }

  .Icon {
    margin-left: 5px;
  }

  .EventId {
    margin: 0 0 0 10px;
  }
}

.Dropdown {
  background-color: $dark-mine-shaft;
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 100%;
  border: 1px solid $emperor;

  &:hover {
    border-color: $gray;
  }
}

.DropdownItem {
  @include body2();
  @include cubic-transition(background-color);

  padding: $x-small;
  height: $xx-large;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-bottom: 1px solid $emperor;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    border: 0;
    outline: 1px solid $gray;
  }
}

.DropdownMainContent {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  align-items: center;
  @include breakpoint(medium) {
    display: none;
  }
}

.PigLinkButton {
  display: none;
  @include breakpoint(medium) {
    display: flex;
  }
}

.IconWrapper {
  margin-right: $x-small;
  display: flex;
}

.DropdownLabel {
  white-space: nowrap;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DropdownButton {
  background: transparent;
  border: 0;
  color: $white;
}

.MobileMenu {
  position: absolute;
  top: 45px;
  right: 42px;
  min-width: 200px;
  z-index: 1;
  display: none;

  &Open {
    display: block;
  }
}
