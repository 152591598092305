@import "../../styles/colors";
@import "../../styles/variables";

.Wrapper {
  display: grid;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Spinner {
  animation: rotate 1s linear infinite;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8%;
    border-radius: 50%;
    background: conic-gradient($mine-shaft 180deg, $blue-ribbon);
    mask: linear-gradient($white 0 0) content-box, linear-gradient($white 0 0);
    mask-composite: exclude;
  }

  &::after {
    // adds a rounded end to the blue streak
    content: "";
    width: 8%;
    height: 8%;
    left: 45%;
    background-color: $blue-ribbon;
    position: absolute;
    border-radius: 50%;
  }
}

.Percentage,
.Spinner {
  margin: auto;
  grid-column: 1;
  grid-row: 1;
}

.Percentage {
  font-variant-numeric: tabular-nums;
  letter-spacing: 0;
  font-weight: $font-semibold;
}
