@import "../../styles/variables";
@import "../../styles/colors";

.Content {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: none;
}

.Fixed {
  width: 100%;
  height: auto;
}

.Scroll {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
