@import "../../styles/variables";
@import "../../styles/colors";

.ListMapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
}

.HeaderWrapper {
  width: 100%;
  height: auto;
  border: 0;
  @include breakpoint(large) {
    border-top: 1px solid $mine-shaft;
    border-bottom: 1px solid $mine-shaft;
  }
}
