@import "../../../styles/variables";
@import "../../../styles/colors";

.Content {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.Preview {
  display: flex;
  padding: 0;
  border-top: 1px solid #525252;
  background-color: $dark-mine-shaft;
  flex-direction: row;
  justify-content: center;
}

.PreviewImage {
  max-width: 800px;
  width: calc(100% - 100px);
}

.Iframe {
  max-width: 800px;
  width: calc(100% - 100px);
  border: 0;
  height: calc(100vh - 330px);
}

@include breakpoint(medium) {
  .Content {
    margin: 20px 0 0;
  }

  .Iframe {
    height: calc(100vh - 300px);
  }
}

@include breakpoint(large) {
  .Content {
    margin: 20px 0 0;
  }

  .Iframe {
    height: calc(100vh - 210px);
  }
}
