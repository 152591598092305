@import "../../styles/variables";
@import "../../styles/colors";

.ShadingSelector {
  position: relative;
  display: flex;
  padding: $small;
  padding-right: $x-small;
  float: right;
  flex-flow: column;
  margin-right: 35px;
  justify-content: flex-start;
  margin-bottom: -40px;
  z-index: 10;
}

.Wrapper {
  position: relative;
  padding: $medium;
  padding-right: 0;
}

.TitleWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.Title {
  display: block;
  margin-right: $small;
}

.SubTitle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  margin-left: 30px;
  transform: translateX(-50%) rotate(-90deg);
}

.Plot {
  $margin: $xx-large;

  width: calc(100% - #{$margin});
  margin-bottom: $x-large;
  margin-left: $margin;

  &.Loading {
    opacity: 0.15;
  }
}

.Loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
}

.ChartTypeWrapper {
  display: flex;
  flex-flow: row nowrap;
}

@include breakpoint(large) {
  .Wrapper {
    padding: $xx-large;
    padding-right: 0;
  }

  .SubTitle {
    margin-left: 50px;
  }
}
