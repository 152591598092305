@import "../../../../styles/colors";
@import "../../../../styles/variables";

.Content {
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
}

.SignatureCardGroup {
  display: flex;
  flex-flow: row;
}

.SignatureField {
  display: flex;
  flex-flow: column;
  margin-right: 46px;

  &Value {
    margin: 0;
  }
}

.FileNameGroup {
  display: flex;
  // So ellipsis work: https://css-tricks.com/flexbox-truncated-text/
  min-width: 0;
  align-items: center;
}

.FileName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 15px;
  line-height: 1;
}

.FileDetailsGroup {
  justify-content: space-between;
  margin: 20px 0;
}

.DownloadButtonGroup {
  flex-grow: 1;
}

.DownloadWrapper {
  width: 100%;
  &:focus {
    outline: solid 1px $white;
    outline-offset: 1px;
  }
}

.DownloadButton {
  width: 100%;
}

@include breakpoint(medium) {
  .Content {
    flex-flow: row;
    align-items: center;
    padding: 30px 60px;
    justify-content: space-between;
  }

  .FileNameGroup {
    flex-grow: 1;
  }

  .FileDetailsGroup {
    justify-content: start;
    margin: 0;
  }

  .DownloadButtonGroup {
    flex-grow: inherit;
  }
}
