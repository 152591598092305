@import "../../styles/colors";
@import "../../styles/variables";

.HeartbeatsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.HeartbeatsTitle {
  margin: 0;
}

@include breakpoint(medium) {
  .HeartbeatsList {
    padding: 30px;
  }
}
