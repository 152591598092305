@import "../../../../styles/colors";
@import "../../../../styles/variables";

.Card {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  padding: 16px;
  margin: 0;
  margin-bottom: 10px;
  border: solid 0.25px $mine-shaft;
  border-left-style: none;
  background-color: $dark-mine-shaft;
  text-align: unset;
  transition: background-color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    top: -0.25px;
    left: 0;
    bottom: -0.25px;
    width: 4px;
    transition: width 0.3s ease;
    background-color: $blue-ribbon;
  }

  &:focus {
    border-color: $white;
    outline: none;
  }

  &:hover {
    background-color: $black;
  }

  &Selected {
    background-color: $mine-shaft;

    &::after {
      width: 8px;
    }

    &:hover {
      background-color: $black;
    }
  }

  &Medium {
    color: $christine;

    &::after {
      background-color: $christine;
    }
  }

  &High {
    color: $alizarin-crimson;

    &::after {
      background-color: $alizarin-crimson;
    }
  }
}

// Desktop Styles
@include breakpoint(large) {
  .Card {
    padding: 10px 10px 10px 24px;
  }
}
