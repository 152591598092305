@import "../../styles/variables";
@import "../../styles/colors";

.Content {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: none;
}

.Fixed {
  width: 100%;
  height: auto;
}

.Scroll {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  padding-bottom: 10px;
}

.MapWrapper {
  min-height: 450px;
  width: 100%;
  background-color: $dark-mine-shaft;
}

.SchematicMapWrapper {
  height: 206px;
  width: 100%;
  background-color: $dark-mine-shaft;
}
