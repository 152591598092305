@import "../../../styles/variables";
@import "../../../styles/colors";

.TimelineInfoBar {
  display: flex;
  height: 60px;
  padding: 0 $medium;
  justify-content: flex-start;
  align-items: center;
  background-color: $dark-mine-shaft;
  border-bottom: 1px solid $mine-shaft;
  flex-shrink: 0;
  position: relative;
}

@include breakpoint(large) {
  .TimelineInfoBar {
    height: 81px;
  }
}

.DateText {
  margin-right: $small;
}

.StretchElement {
  flex: 1 0 auto;
}
