@import "../../../styles/colors";
@import "../../../styles/variables";

.CheckboxItem {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid $mine-shaft;
  padding: $x-small;

  &:hover {
    background-color: $mine-shaft;
    cursor: pointer;
  }

  &Small {
    height: 32px;
    padding: $xx-small $x-small;
  }
}

.Highlighted {
  background-color: $mine-shaft;
  cursor: pointer;
}

.CheckboxLabel {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;

  &WithAltText {
    margin-right: 40px;
  }
}

.CheckboxInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;

  &:focus + .CheckboxLabel {
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.CheckboxWrapper {
  display: flex;
  flex-direction: column;
}

.AltTextWrapper {
  display: flex;
  margin-left: auto;
}
