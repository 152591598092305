@import "../../../styles/variables";
@import "../../../styles/colors";

.FileDetails {
  background-color: $dark-mine-shaft;
  padding: 15px 12px;
  margin-bottom: 20px;
}

.FileTitle {
  margin-bottom: 15px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.File {
  display: none;
}

.Date {
  margin-bottom: 15px;
}

.Text {
  margin: 0;
}

@include breakpoint(large) {
  .Details {
    display: flex;
  }

  .Date {
    margin-right: 50px;
    margin-bottom: 0;
  }

  .File {
    display: block;
    margin-right: 30px;
  }

  .FileTitle {
    margin-bottom: 27px;
  }

  .FileDetails {
    padding: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
}
