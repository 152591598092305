@import "../../styles/variables";
@import "../../styles/colors";

.StatusIndicator {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.Indicator {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 100%;
  background-color: $warning;

  &.Live {
    background-color: $success;
    box-shadow: 0 0 0 0 $success;
    animation: livePulse 2.5s infinite;
  }

  &.Paused {
    background-color: $error;
  }
}

.Toggle {
  margin-left: $small;
}

@keyframes livePulse {
  0% {
    box-shadow: 0 0 0 0 rgba($success, 0.5);
  } 85% {
    box-shadow: 0 0 0 12px rgba($success, 0);
  } 100% {
      box-shadow: 0 0 0 24px transparent;
  }
}
