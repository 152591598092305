/* stylelint-disable */

@import "../../../styles/colors";
@import "../../../styles/variables";

.Container {
  flex: 1 0 auto;
  flex-flow: row nowrap;
  display: flex;
  padding: 0;
}

 .KPRangeSlider {
  display: flex;
  padding-top: 0;
  padding-right: 0;
  min-width: 220px;
  position: relative;
  margin-bottom: -22px;
}

.ResetButton {
  display: flex;
  width: 140px;
  right: $medium;
  padding: 0 $small;
  background-color: $mine-shaft;
  color: $white;
}

.ResetButton:hover {
  background-color: $dark-mine-shaft;
}

.InactiveResetButton {
  filter: brightness(60%);
  background-color: transparent !important;
}
