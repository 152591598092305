@import "../../../styles/colors";
@import "../../../styles/variables";

.Org {
  border: 1px solid $mine-shaft;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &Active {
    background-color: $white;
  }
}

.OrgNameActive {
  color: $dark-mine-shaft;
}

.Activate {
  width: 100%;
}

.Details {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}

.Logo {
  margin-right: 29px;
  display: flex;
  padding-top: 5px;
}

.PipelineCount {
  margin-bottom: 10px;
}

.ActiveTitle {
  margin-right: 20px;
}

.ActiveStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  // match height of button to make cards equal height
  margin-top: 1px;
}

.CountWrapper {
  margin-top: 5px;
}

.Summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

.OrgInfo {
  flex-grow: 1;
  margin-left: 29px;
}

@include breakpoint(large) {
  .Summary {
    justify-content: safe;
  }

  .Org {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
  }

  .OrgInfo {
    display: flex;
    flex-direction: row;
  }

  .Activate {
    width: unset;
  }

  .Headings {
    width: 100%;
    max-width: 400px;
  }

  .Details {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .PipelineCount {
    margin-bottom: 0;
  }

  .Logo {
    padding-top: 0;
    align-items: center;
  }

  .EventCount {
    align-self: center;
    margin-right: 40px;
  }

  .Status {
    width: 190px;
  }
}
