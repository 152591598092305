@import "../../../../styles/colors";
@import "../../../../styles/variables";

.Name {
  margin-bottom: 25px;
}

.Content {
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
}

@include breakpoint(medium) {
  .Content {
    padding: 30px 60px;
  }
}
