@import "../../../styles/colors";
@import "../../../styles/variables";

.Wrapper {
  padding: $x-large $medium;
  border-top: 1px solid $mine-shaft;

  &:first-child {
    border-top: 0;
  }

  &:last-child {
    flex-grow: 1;

    @include breakpoint(large) {
      flex-grow: initial;
    }
  }
}

.Header {
  display: flex;
  flex-flow: column;
  color: $white;
  width: 100%;
}

.TypeGroup {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: $medium;
  text-transform: capitalize;
}

.Title {
  margin-bottom: $medium;

  &Updates {
    margin-bottom: 0;
  }
}

.Text {
  margin: 0;
}

.Label {
  width: 30%;
  margin: 0;
}

.SmallText {
  margin: 0;
  color: $dove-grey;
}

.SelectGroup {
  align-items: center;
}

.Notes {
  margin-top: $medium;
}
