@import "../../styles/variables";

.ButtonWrap {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

@include breakpoint(large) {
  .UploadDesktop {
    margin-left: 22px;
  }
}
