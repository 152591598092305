@import "../../styles/colors";

.LinkIconCount {
  height: 26px;
  min-width: 26px;
  background-color: $error;
  border-radius: 26px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;
  margin: 0;
}

.Bounce {
  animation: bounce 1s infinite 0s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
