@import "../../styles/colors";
@import "../../styles/variables";

.Modal {
  position: fixed;
  background: $dark-mine-shaft;
  outline: none;
  width: 100%;
}

.Open {
  overflow: hidden;
}

.Overlay {
  background-color: rgba($black, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0;
  z-index: 999;
}

@include breakpoint(large) {
  .Modal {
    margin-left: auto;
    margin-right: auto;
  }

  .Overlay {
    align-items: center;
  }
}
