@import "../../styles/colors";
@import "../../styles/variables";

.Screen {
  height: 100%;
}

.HeaderWrapper {
  width: 100%;
}

.Header {
  display: flex;
  width: 100%;
  flex-flow: column-reverse;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-start;
}

.Logo {
  display: flex;
  flex: 1 0 auto;
  color: $blue-ribbon;
}

.HeartbeatWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}

.Heartbeat {
  z-index: 100; // Required to sit above Sidebar Toggle Overlay.
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-mine-shaft;
  padding: 12px 20px;
  width: 100%;
  height: $mobile-header-height;

  &Link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.StatusText {
  margin-right: 10px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.Content {
  position: fixed;
  top: $mobile-header-height;
  bottom: $mobile-footer-height;
  right: 0;
  left: 0;
  padding: 0;

  &Container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
}

.DesktopHeader {
  display: none;
}

.Subtitle {
  margin-left: 11px;
}

.BreadCrumb {
  display: flex;
  align-items: center;
  margin-bottom: $xx-small;
  font-weight: 600;
}

.LinkIcon {
  transform: rotate(90deg);
  transform-origin: center;
  display: inline-block;
  width: 16px;
  height: 16px;
  color: $silver;
}

// Desktop Styles
@include breakpoint(large) {
  .DesktopHeader {
    display: flex;
    align-items: center;
    &WithLink {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .HeaderWrapper {
    background-color: $cod-gray;
    position: fixed;
    top: 0;
    z-index: 99;
    // To work around a bug in stylelint
    // https://github.com/stylelint/stylelint/issues/4114
    // stylelint-disable-next-line function-calc-no-invalid
    max-width: calc(100vw - #{$desktop-sidebar-width});
  }

  .Header {
    display: flex;
    min-height: $desktop-header-height;
    flex-flow: row nowrap;
    padding: 0 20px;
    align-items: center;
    &WithLink {
      padding: 30px 20px;
    }
  }

  .Logo {
    display: none;
  }

  .HeartbeatWrapper {
    position: relative;
    width: auto;
  }

  .Heartbeat {
    background-color: $cod-gray;
    justify-content: space-between;
    padding: 0;
    width: auto;
    height: auto;
  }

  .Content {
    position: fixed;
    top: $desktop-header-height;
    bottom: 0;
    right: 0;
    left: $desktop-sidebar-width;
  }
}
