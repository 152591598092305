@import "../../styles/variables";
@import "../../styles/colors";

.MobilePageTitle {
  width: 100%;
  display: block;
  padding: 20px $medium;
  border: 1px solid $mine-shaft;
}

@include breakpoint(large) {
  .MobilePageTitle {
    display: none;
  }
}
