@import "../../../styles/variables";
@import "../../../styles/colors";

.Wrapper {
  flex: 1 0 350px;
  display: flex;
  flex-flow: column;
  padding: 20px;
  border-bottom: 1px solid $mine-shaft;
}

.Row {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: auto;

  &:not(:last-child) {
    margin-bottom: $xx-small;
  }
}

.Text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Link {
  text-decoration: underline;
  text-underline-position: under;
}

.Wrap {
  white-space: pre-wrap;
}

.Divider {
  width: 100%;
  border: 0;
  border-top: 1px solid $mine-shaft;
  margin: $small 0 $medium;
}

@include breakpoint(medium) {
  .Wrapper {
    background-color: $dark-mine-shaft;
    padding: $medium $x-large $medium $xx-large;
    border-left: 1px solid $mine-shaft;
    border-right: 1px solid $mine-shaft;
  }
}

@include breakpoint(xlarge) {
  .Wrapper {
    border: 0;
  }
}
