@import "../../styles/colors";
@import "../../styles/variables";

.Pipeline {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  border: solid 0.5px $dove-grey;
  border-left: 0;
  background-color: $dark-mine-shaft;
}

.PipelineHeader {
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px 35px 18px 18px;

  &::after {
    position: absolute;
    content: "";
    top: -0.5px;
    bottom: -0.5px;
    left: -0.5px;
    width: 4px;
    background-color: $success;
  }

  &PartiallyConnected {
    &::after {
      background-color: $error;
    }
  }

  &Disconnected {
    &::after {
      background-color: $dove-grey;
    }
  }

  &Error {
    &::after {
      background-color: $dove-grey;
    }
  }
}

.PipelineButton {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  cursor: pointer;
  background-color: transparent;
  border-style: none;
}

.CircleIcon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: $white;
  background-color: $success;

  &PartiallyConnected {
    background-color: $error;
  }

  &Disconnected {
    background-color: $dove-grey;
  }

  &Error {
    background-color: $dove-grey;
  }
}

.Info {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-align: left;

  &Segments {
    margin-bottom: 5px;
    color: $silver-chalice;
    font-size: 1rem * (10/16);
  }

  &Name {
    color: $white;
    font-size: 1rem * (14/16);
  }
}

.SegmentList {
  height: 0;
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  overflow: hidden;

  &Open {
    border-top: solid 0.5px $dove-grey;
    border-left: solid 0.5px $dove-grey;
    height: auto;
    padding: 14px 20px;
  }
}

.SegmentItem {
  flex: 1 0 100%;
  margin: 6px 0;
  padding: 4px 8px;
  background-color: $error;
  color: $white;

  &DisconnectedText {
    text-align: center;
  }
}

.Toggle {
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
  display: block;
  width: 10px;
  height: 10px;
  color: $white;
  margin-left: auto;
}

@include breakpoint(medium) {
  .Pipeline {
    flex-flow: row;
  }

  .PipelineHeader {
    padding: 18px 30px;
  }

  .PipelineButton {
    display: none;
  }

  .CircleIcon {
    margin-right: 27px;
  }

  .Info {
    flex: 1 0 auto;

    &Name {
      font-size: inherit;
    }
  }

  .SegmentList {
    height: auto;
    padding: 10px 30px 10px 0;
    border-style: none;
    justify-content: flex-end;
  }

  .SegmentItem {
    flex: 0 0 auto;
    margin-left: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .Toggle {
    display: none;
  }
}
