@import "../../styles/colors";
@import "../../styles/variables";

$schema-margin-left: 70px;
$ten-pixels: 0.625rem;
$twelve-pixels: 0.75rem;

.Container {
  position: relative;
  width: 100%;
  height: 210px;
  padding: $medium $medium $small $xx-large;
}

.SchematicMap {
  width: 100%;
  background-color: $dark-mine-shaft;
  overflow: visible;
  height: 100%;
}

.YAxis {
  color: $gray;
  font-size: $twelve-pixels;
  text-transform: capitalize;
  transform: translate($schema-margin-left, 0);
}

.Line {
  stroke: $gray;
  color: $gray;
  stroke-width: 1;
  font-size: $ten-pixels;

  &Events {
    transform: translate($schema-margin-left, 0);
  }

  &Posts {
    font-size: 13px;
    transform: translate($schema-margin-left, 70px);

    &Text {
      stroke-width: 0.5;
    }
  }

  &PostsBlue {
    font-size: 13px;
    color: $blue-ribbon;
    transform: translate($schema-margin-left, 70px);

    &Text {
      stroke-width: 0.5;
    }
  }

  &PostsTop {
    transform: translate($schema-margin-left, 52px);
    font-size: 9px;

    &Text {
      stroke-width: 0.35;
    }
  }

  &PostsBot {
    transform: translate($schema-margin-left, 88px);
    font-size: 9px;

    &Text {
      stroke-width: 0.35;
      font-size: 11px;
    }
  }

  &Assets {
    transform: translate($schema-margin-left, 0);
  }
}

.IconGroup {
  transform: translate($schema-margin-left, 0);
}

.Icon {
  stroke: $gray;
  &Event {
    color: $white;

    &Low {
      fill: $blue-ribbon;
    }
    &Medium {
      fill: $christine;
    }
    &High {
      fill: $alizarin-crimson;
    }
  }

  &Asset {
    fill: $cod-gray;
    color: $white;
  }
}

.EventFeaturedLine {
  stroke: $gray;
  fill: $gray;
  stroke-width: 1px;
  stroke-dasharray: 3px;
}

.EventText {
  stroke: $gray;
  fill: $gray;
  stroke-width: 0.2;
  font-size: $twelve-pixels;
  text-transform: capitalize;
  opacity: 0;

  &Focus {
    opacity: 1;
  }
}

.AssetText {
  stroke: $gray;
  fill: $gray;
  stroke-width: 0.2;
  font-size: $ten-pixels;
  text-transform: capitalize;
  opacity: 0;

  &Focus {
    opacity: 1;
  }
}

.Fadable {
  transition: opacity 0.4s ease-out;
}

.FadeOut {
  opacity: 0;
}

.FadeOutXAxis {
  opacity: 0;

  &:last-child {
    opacity: 1;
  }
}
