@import "../../../styles/variables";
@import "../../../styles/colors";

.UploadModal {
  width: calc(100% - 70px);
  margin: 0 auto;
  padding: 30px 18px;
  top: 85px;
}

.FileText {
  margin: 0;
  margin-bottom: 10px;
}

.File {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 20px;
}

.ModalTitle {
  margin-bottom: 29px;
}

.FileName {
  margin: 0 0 3px;
}

.Title {
  margin-bottom: 30px;
}

.Buttons {
  display: flex;
}

.Button {
  flex: 1;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
}

.Alert {
  color: $error;
  border: 1px solid $error;
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 12px;
  margin-bottom: 9px;

  &Text {
    margin-left: 12px;
  }
}

.FileInput {
  background-color: $mine-shaft;
  border: 0;
  color: $white;
}

.Uploading {
  display: flex;
  flex-flow: row;
}

@include breakpoint(medium) {
  .UploadModal {
    max-width: 565px;
  }
}

@include breakpoint(large) {
  .UploadModal {
    max-width: 565px;
    padding: 30px;
    top: 255px;
  }

  .Buttons {
    justify-content: flex-end;
  }

  .Button {
    flex: none;
  }
}
