@import "../../styles/variables";
@import "../../styles/colors";

.TableWrapper {
  overflow-y: auto;
  border: 1px solid $mine-shaft;
}

.Table {
  border-collapse: collapse;
  width: 100%;
  background: $cod-gray;
}

.TableRow {
  width: 100%;
  padding: 0;
  &:not(:last-child) {
    border-bottom: 1px solid $mine-shaft;
  }
  &:hover {
    background-color: $almost-black;
  }
}

.TableCell {
  padding: $medium $x-small;
  border-collapse: collapse;
  font-weight: 400;
  font-size: 14px;
  line-height: $medium;
  letter-spacing: 0.16px;
  min-height: 56px;
  text-transform: capitalize;
  vertical-align: top;
  white-space: pre-line;
  z-index: 0;
  cursor: pointer;
}

.TableHeader {
  padding: $medium $x-small;
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;
  vertical-align: top;
  min-height: 56px;
  background: $dark-mine-shaft;
  position: sticky;
  top: 0;
  z-index: 1;
}

.TableCaption {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
