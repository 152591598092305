@import "../../styles/colors";
@import "../../styles/variables";

.FilterWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}

.Filter {
  display: block;
  background-color: $dark-mine-shaft;
}

.DatePickerToggle {
  display: none;
}

.Buttons {
  display: none;
}

.Wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
}

.NavIconWrapper {
  &Prev {
    position: absolute;
    left: 30px;
    top: 20px;
    width: 40px;
  }

  &Next {
    position: absolute;
    right: 6px;
    top: 20px;
    width: 40px;
  }
}

.DatePickerLabel {
  display: none;
}

@include breakpoint(large) {
  .FilterWrapper {
    position: relative;
    display: block;
    height: auto;
    z-index: 110; //Must be greater than EventDetails modal z-index.
  }

  .Filter {
    display: none;
    position: absolute;
    padding: 40px 5px;
    border: 1px solid $emperor;
    top: calc(100% - 1px);

    &Open {
      display: block;
    }
  }

  .DatePickerToggle {
    display: inline;
  }

  .DatePickerLabel {
    display: block;
    margin: 0 0 8px;
  }

  .Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 34px 0;
  }

  .NavIconWrapperPrev,
  .NavIconWrapperNext {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: auto;
  }

  .DatePickerWrapper {
    max-width: 100%;
    text-overflow: ellipsis;
  }
}
