@import "../../styles/variables";
@import "../../styles/colors";

.Content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.OrgList {
  margin: 0 20px;
  overflow-y: auto;
  flex-grow: 1;
  height: 100%;
}

.CompanyLogout {
  background-color: $mine-shaft;
  width: 100%;

  &Content {
    padding: 22px 0;
    display: flex;
    align-items: center;
  }
}

.CompanyInfo {
  margin-left: 16px;
  flex-grow: 1;
}

.Avatar {
  margin-left: 20px;
}

.Logout {
  margin-right: 20px;
}

@include breakpoint(large) {
  .OrgList {
    margin-top: 0;
    min-height: unset;
    margin-bottom: 100px;
  }

  .CompanyLogout {
    bottom: 0;
    position: fixed;
    width: calc(100% - 77px);
  }

  .CompanyInfo {
    margin-left: 30px;
  }

  .Avatar {
    margin-left: 40px;
  }

  .Logout {
    margin-right: 40px;
  }
}
