@import "../../../../styles/colors";
@import "../../../../styles/variables";

.Title {
  margin-left: 60px;
  margin-bottom: 25px;
}

.Content {
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
}

.SignatureImage {
  display: block;
  width: 85%;
  margin: auto;
  height: auto;
}

@include breakpoint(medium) {
  .Content {
    padding: 30px 60px;
  }
}
