@import "../../styles/variables";
@import "../../styles/colors";

$desktop-details-modal-top-margin: $small;
$desktop-details-modal-bottom-margin: $small;

.DetailsModalMask {
  display: none;
}

.DetailsModal {
  z-index: 100;
  position: fixed;
  top: $mobile-header-height;
  bottom: $mobile-footer-height;
  left: 0;
  right: 0; // Needed to hide duplicate scroll-bar on mobile (Chrome)
  display: flex;
  border: solid 1px $mine-shaft;
  width: 100%;
  background-color: $cod-gray;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@include breakpoint(large) {
  .DetailsModalMask {
    z-index: 99; // Event modal z-index set to 100;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-style: none;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: $black;
    animation: fadeIn 0.1s linear;
    opacity: 0.4;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  .DetailsModal {
    position: absolute;
    display: flex;
    top: $desktop-details-modal-top-margin;
    bottom: 0;
    border: solid 1px $mine-shaft;
    width: calc(100% - 100px);
    height: auto;
    /* stylelint-disable function-calc-no-invalid */
    max-height: calc(
      100% - #{$desktop-details-modal-top-margin} - #{$desktop-details-modal-bottom-margin}
    );
    /* stylelint-enable function-calc-no-invalid */
    background-color: $cod-gray;
    padding: 0 $x-small $x-small;
    margin: 0 auto;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2);

    &Short {
      /* stylelint-disable function-calc-no-invalid */
      max-height: calc(
        100% - #{$desktop-details-modal-top-margin} - #{$desktop-details-modal-bottom-margin} -
          #{$tabs-height-desktop}
      );
      /* stylelint-enable function-calc-no-invalid */
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.4;
  }
}
