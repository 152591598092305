@import "../../styles/colors";

.Switch {
  position: relative;
  display: block;
  width: 36px;
  height: 20px;
  background-color: $mine-shaft;
  border: 0;
  border-radius: 18px;
  margin: 0 5px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid $mine-shaft;
    background-color: $wild-sand;
    transition: all 250ms;
  }
}

.active {
  &::after {
    content: "";
    left: calc(100% - 20px);
  }
}

.ToggleSwitch {
  display: flex;

  &:hover {
    .Switch {
      &::after {
        background-color: $silver-chalice;
      }
    }
  }
}
