@import "../../styles/colors";
@import "../../styles/variables";

.DayPicker__hidden {
  // not sure why this override is necessary. This class is toggled when
  // `focusedInput` is not defined, but when I inspect the component, the required
  // `focusedInput` prop is defined correctly compared to the docs and examples
  visibility: visible;
}

.DayPicker_transitionContainer__horizontal {
  min-height: 285px;
}

.Calendar,
.CalendarDay,
.CalendarMonth,
.CalendarMonthGrid,
.DayPicker,
.DayPickerNavigation_button__default {
  background: $dark-mine-shaft;
  border: 0;
}

.DayPickerNavigation {
  &_button__default {
    &:hover,
    &:focus,
    &:active {
      background: $mine-shaft;
      border-color: $white;
      border-radius: 0;
    }
  }

  &_button__disabled {
    border: 0;
    color: $mine-shaft;
    &:hover {
      border: 0;
    }
  }

  &_svg__horizontal {
    fill: $white;
  }
}

.DayPicker_weekHeader_li,
.CalendarMonth_caption {
  font-size: 0.875rem;
}

.DayPicker_weekHeader_li {
  color: $gray;
}

// Adjust vertical space between month and days
.DayPicker_weekHeader {
  top: 56px;
}

/* stylelint-disable selector-max-type */
.CalendarMonth_caption {
  color: $white;

  strong {
    font-weight: $font-regular;
  }
}
/* stylelint-enable selector-max-type */

.CalendarDay {
  color: $white;

  &:active,
  &:hover,
  &:focus {
    border: 0;
    color: $white;
    background: $mine-shaft;
    border-radius: 50%;
  }

  &__hovered_span {
    background: $mine-shaft;
    + .CalendarDay:hover {
      border-radius: 0 50% 50% 0;
    }
  }

  &__blocked_out_of_range {
    background: transparent;
    color: $gray;

    &:hover {
      background: transparent;
      color: $gray;
    }
  }
}

.CalendarDay__hovered_span.CalendarDay__firstDayOfWeek:hover {
  border-radius: 0 50% 50% 0;
}

.CalendarDay__selected {
  $fake-background-size: 50;

  /** creates a bunch of 1px shadows each offset by one more pixel than the last
   *  this is used to create a long shadow off of an element while preserving the
   *  border-radius on either end.
   *    $size: how long the shadow should be (sans unit please)
   *    $direction: the direction the shadow should stretch into: 1 = right, -1 = left
   */
  @mixin fake-background($size, $direction: 1) {
    $shadow: ();

    @for $i from 1 through $size {
      $shadow: append($shadow, unquote("#{$i * $direction}px 0 0 0 #{$blue-ribbon}"), comma);
    }

    box-shadow: $shadow, inset 0 0 0 2px $blue-ribbon;
  }

  position: relative;
  color: $white;
  background: $blue-ribbon;

  &:active,
  &:hover,
  &:focus {
    color: $white;
    background: $blue-ribbon;
  }

  &_start {
    border-radius: 50% 0 0 50%;

    &:hover {
      @include fake-background($fake-background-size);

      overflow: hidden;
      border-radius: 100%;
      background-color: $science-blue;
    }

    &.CalendarDay__selected_end {
      border-radius: 100%;

      &:hover {
        border-radius: 100%;
        box-shadow: inset 0 0 0 2px $blue-ribbon;
      }
    }
  }

  &_end {
    border-radius: 0 50% 50% 0;

    &:hover {
      @include fake-background($fake-background-size, -1);

      overflow: hidden;
      border-radius: 100%;
      background-color: $science-blue;
    }
  }

  &_span {
    color: $white;
    background: $blue-ribbon;

    &:hover {
      overflow: hidden;
      color: $white;
      background-color: $science-blue;
      border-radius: 100%;
      box-shadow: 0 0 0 ($fake-background-size * 1px) $blue-ribbon,
        inset 0 0 0 2px $blue-ribbon;
    }
  }
  // stylelint-disable-next-line selector-max-type
  &_start_in_hovered_span
    + .CalendarDay__hovered_span:not(.CalendarDay__selected):not(.CalendarDay__selected_span):hover {
    border-radius: 0 50% 50% 0;
  }
}

@include breakpoint(large) {
  .DayPickerNavigation {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    padding: 0 32px;
  }

  // Adjust vertical space between month and days
  .DayPicker_weekHeader {
    top: 50px;
  }

  .CalendarMonth_caption {
    padding-top: 0;
    padding-bottom: 60px;
    line-height: 22px;
  }
}
