@import "../../../../styles/colors";
@import "../../../../styles/variables";

.SignatureCard {
  display: flex;
  flex-flow: column;
  padding: 0;
  background-color: $dark-mine-shaft;

  // ensure no top border for first card
  &:first-child {
    border-top-style: none;
  }

  // ensure no bottom border for last card
  &:last-child {
    border-bottom-style: none;
  }
}

.Border {
  border-bottom: solid 1px $mine-shaft;
  border-top: solid 1px $mine-shaft;

  // Remove top border if not first or class
  + .Border {
    border-top-style: none;
  }
}
