.singlePipeline {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  /* stylelint-disable-next-line selector-max-universal */
  > * {
    margin-right: 12px;
  }
}

.changeButton {
  margin-right: 0;
  margin-left: auto;
}
