@import "../../../styles/colors";

$background-color: $dark-mine-shaft !default;
$active-background-color: $mine-shaft !default;
$hover-background-color: $almost-black !default;
$padding: 20px;
$border-width: 1px;

.recentSearchItem {
  display: block;
  width: 100%;
  padding: $padding;
  background-color: $background-color;
  min-width: 300px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.active {
    background-color: $active-background-color;
  }

  &:hover {
    background-color: $hover-background-color;
    border: $border-width solid $silver-chalice;
    padding: ($padding - $border-width);
  }

  &:focus {
    outline: none;
    border: $border-width solid $white;
    padding: ($padding - $border-width);
  }
}

.textRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.arrowRight {
  display: inline-block;
  line-height: 1em;;
  vertical-align: middle;
  margin: 0 0.5em;
}

