@import "../../styles/variables";
@import "../../styles/colors";

.Share {
  position: absolute;
  top: calc(100% + 10px);
  right: 20px;
  background-color: $dark-mine-shaft;
  padding: $xx-large;
  z-index: 110;
  display: flex;
  flex-flow: column;
  border: solid 1px $mine-shaft;
  border-top-style: none;
  filter: drop-shadow(0 -1px 0 $mine-shaft);
}

.Row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.InputWrapper {
  margin-right: $x-small;
  /* stylelint-disable selector-max-type */
  input {
    background-color: $cod-gray;
  }
  /* stylelint-enable selector-max-type */
}

@include breakpoint(medium) {
  .InputWrapper {
    width: 300px;
  }
}

.ButtonScreenshot {
  border-style: none;
  background-color: transparent;
  color: $white;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  transition: 0.3s color ease-in-out;

  &:hover {
    color: $link-secondary-hover;
  }
  &:focus {
    color: $link-secondary-focus;
    outline: 1px solid $link-secondary-hover;
  }
}
