@import "../../styles/colors";
@import "../../styles/variables";

.Loading {
  height: 100%;
  width: 100%;
}

.Container {
  position: relative;
  height: 100%;
  width: 100%;
}

.Map {
  height: 100%;
  width: 100%;

  /* stylelint-disable-next-line selector-max-type */
  iframe {
    background-color: $dark-mine-shaft; /* Set loading tile background (Issue: https://github.com/tomchentw/react-google-maps/issues/764) */
  }
}

.MapOptions {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 18px 0 6px;
  margin: 20px 20px 40px;
  display: flex;
  flex-flow: column;
  background-color: $dark-mine-shaft;
  border: solid 1px $emperor;
}

.LegendDivider {
  width: 100%;
  border: solid 1px $emperor;
}
