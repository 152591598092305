@import "../../../styles/variables";

.List {
  margin: 0 20px;
  padding: 20px 0 10px;
}

.ListItem {
  margin-bottom: 10px;
}

.Empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@include breakpoint(large) {
  .List {
    margin: 20px;
    padding: 0 0 10px;
  }
}
