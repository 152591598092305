@import "../../../styles/variables";
@import "../../../styles/colors";

.TableWrapper {
  &Desktop {
    padding: 10px;
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
  &Mobile {
    margin-top: 20px;
    padding: 0 20px;
    @include breakpoint(medium) {
      display: none;
    }
  }
}

.NoMatchWrapper {
  @include breakpoint(medium) {
    // To work around a bug in stylelint
    // https://github.com/stylelint/stylelint/issues/4114
    // stylelint-disable-next-line function-calc-no-invalid
    height: calc(100% - #{$tabs-height-desktop} - #{$filters-height-desktop});
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.DownloadLink {
  margin: 20px;
  @include breakpoint(medium) {
    margin: 10px;
    display: inline-flex;
  }
}

.LinkWrapper {
  display: inline-block;
  width: 100%;
}

.FilterBarWrapper {
  background-color: $dark-mine-shaft;
  align-items: center;
  justify-content: space-between;
  padding: $medium;
  border-top: 1px solid $mine-shaft;
  border-bottom: 1px solid $mine-shaft;
  display: none;
  position: relative;

  @include breakpoint(large) {
    display: flex;
  }
}

.FilterBarInner {
  display: flex;
  align-items: flex-end;

  &:first-child {
    max-width: calc(100% - 280px);
  }
}

.FilterContainer {
  margin-right: $small;

  &:first-child {
    max-width: unset;
  }

  &Quarter {
    max-width: calc(25% - 15px);
    @include breakpoint(xxxlarge) {
      max-width: unset;
    }
  }

  &Third {
    max-width: calc(33% - 15px);
    @include breakpoint(xxxlarge) {
      max-width: unset;
    }
  }
}

.PaginationText {
  margin: 0 $x-large $x-small 0;
  white-space: nowrap;
}
