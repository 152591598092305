.WrapperContainer {
  display: flex;
  flex-flow: column;
}

.ControlsRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.LeftControls {
  display: flex;
  flex-flow: row;
}

.AudioControlButton {
  padding: 0;
  width: 40px;
  height: 40px;
}

.ElapsedTime {
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
