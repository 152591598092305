@import "../../../styles/colors";
@import "../../../styles/variables";

.Header {
  display: flex;
  flex-flow: column;
  color: $white;
  width: 100%;
}

.TitleGroup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 3px;
  align-items: baseline;
}

.TypeGroup {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

.InfoGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Type {
  margin: 0 10px 0 0;
  text-transform: capitalize;
}

.Id {
  margin: 0;
  white-space: nowrap;
}

.StartTime {
  justify-self: flex-end;
  margin: 0;
  white-space: nowrap;
}

.Details {
  margin: 0;
}
