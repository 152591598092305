@import "../../styles/colors";
@import "../../styles/variables";

.Checkbox {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 12px;

  &NoPad {
    padding-bottom: 0;
  }

  &:hover{
    cursor: pointer;
  }
}

.CheckboxLabel {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;

  &NoCapitalize {
    text-transform: none;
  }
}

.CheckboxInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;

  &:focus + .CheckboxLabel {
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.CheckboxWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 18px;
}

.GroupLabel {
  margin-bottom: 14px;
}
