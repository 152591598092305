@import "../../../../styles/colors";
@import "../../../../styles/variables";

$mobile-filters-header-height: 64px;

.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0 auto;
  background-color: $dark-mine-shaft;
}

.MobileFilterBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* stylelint-disable-next-line selector-max-universal */
  > * {
    margin-right: $xx-small;
    flex-basis: 33.333333%;
  }

  > :first-child {
    margin-left: $xx-small;
  }
}

.FilterButton {
  @include cubic-transition(border-color);

  display: inline;
  background-color: $dark-mine-shaft;
  border: 1px solid $emperor;

  &:hover,
  &:focus {
    background-color: $dark-mine-shaft;
    border: 1px solid $gray;
    outline: none;
  }

  &HasActive {
    background-color: $mine-shaft;
    &:hover,
    &:focus {
      background-color: $mine-shaft;
    }
  }
}

.FilterButtonContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.FiltersCount {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: $white;
  color: $black;
  margin-left: 5px;
}

.ResultsCount {
  display: inline;
  text-align: center;
  max-width: 80px;
  align-self: center;
}

.Overlay {
  top: 43px;
  padding: 0;
  background-color: $dark-mine-shaft;
  /* stylelint-disable-next-line selector-max-universal */
  > * {
    background-color: transparent;
  }
}

.ModalControls {
  display: flex;
  align-items: center;
  z-index: 10;
  height: $mobile-filters-header-height;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid $emperor;
  border-bottom: 1px solid $emperor;
}

.FiltersList {
  max-height: calc(
    100vh - 108px
  ); // subtracts height of page header + modal controls

  overflow-y: auto;
  background: transparent;
}

.Filter {
  padding: 20px;
  border-bottom: 1px solid $emperor;
}

.Close {
  color: $white;
  background-color: transparent;
  border: 0;
  &:hover {
    cursor: pointer;
  }
}

.ShowButton {
  width: calc(100% - 40px);
  height: 48px;
  margin: 20px;
}

@include breakpoint(small) {
  .MobileFilterBar {
    > :first-child {
      margin-left: $x-small;
    }

    /* stylelint-disable-next-line selector-max-universal */
    > * {
      margin-right: $x-small;
    }
  }
}

@include breakpoint(large) {
  .Wrapper {
    display: none;
  }
}
