@import "../../styles/colors";
@import "../../styles/variables";

.Link {
  &::before {
    content: "";
    position: absolute;
    height: 5px;
    width: 100%;
    border-top: 4px solid $blue-ribbon;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s ease;
    opacity: 0;
  }
}

.Toggle {
  z-index: 100;
  display: block;
  position: relative;
  height: auto;
  padding: 16px 10px 27px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  color: #8a8d91;
  text-align: left;
  text-decoration: none;
  transition: 0.3s ease;
  border-style: none;
  outline: none;
  background-color: $dark-mine-shaft;

  &Selected {
    color: $white;
    background-color: $mine-shaft;

    &::before {
      opacity: 1;
    }
  }

  //stylelint-disable-next-line selector-max-type
  span {
    display: block;
    width: 20px;
    height: 2px;
    margin: 0 auto 3px;
    position: relative;
    background: currentColor;
    z-index: 1;
    transform-origin: 0% 50%;
    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.3s ease-in-out;
  }

  &Open {
    color: #ffffff;

    :nth-last-child(4) {
      transform: translate(0, 1px) rotate(45deg);
    }
    :nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    :nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    :last-child {
      opacity: 1;
      transform: translate(0, 0) rotate(-45deg);
    }
  }

  &Overlay {
    display: none;
    z-index: -1;
    opacity: 0;
    border-style: none;
    outline: none;

    &Open {
      z-index: 98;
      display: flex;
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $cod-gray;
      opacity: 0.7;
    }
  }
}

// Desktop Styles
@include breakpoint(large) {
  .Toggle {
    display: none;
  }
}
