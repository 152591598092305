@import "../../styles/variables";

@include breakpoint(large) {
  .ListMapWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    overflow: hidden;
  }
}
