@import "../../styles/variables";
@import "../../styles/colors";

.FilterAttribute {
  padding: 30px 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  &:last-child {
    align-items: stretch;
    height: 100%;
  }
}

.FiltersPanelSeparator {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: solid 1px $mine-shaft;
  height: 0;
  margin: 0;
}
