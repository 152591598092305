@charset "UTF-8";
.LogEntry {
  position: relative;
  padding: 20px 20px 15px 65px;
  background: transparent;
  color: #ffffff;
  text-align: left;
  cursor: pointer; }
  @media screen and (min-width: 1025px) {
    .LogEntry {
      padding-top: 10px;
      padding-bottom: 10px; } }
  .LogEntry::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 29px;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background-color: #393939; }
  .LogEntry:first-child::before {
    top: 20px; }
  .LogEntry:last-child::before {
    bottom: 40px; }
  .LogEntry:only-child::before {
    display: none; }
  .LogEntry:disabled {
    cursor: initial; }
  .LogEntry:hover {
    background-color: rgba(57, 57, 57, 0.7); }
  .LogEntryType {
    margin-left: 10px; }
  .LogEntryIcon {
    position: absolute;
    top: 20px;
    left: 24px;
    width: 10px;
    height: 10px;
    background-color: #8d8d8d;
    border: solid 2px #393939;
    border-radius: 50%;
    color: #ffffff; }
    .LogEntryIconLow {
      background-color: #0062ff; }
    .LogEntryIconMedium {
      background-color: #f37113; }
    .LogEntryIconHigh {
      background-color: #da1e28; }
  .LogEntryWithIcon {
    display: flex;
    padding-left: 50px;
    flex-wrap: wrap; }
  .LogEntrySelected {
    background-color: #262626; }

.PigIcon {
  position: absolute;
  left: 18px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px; }
  .PigIconLow {
    background-color: #0062ff; }
  .PigIconMedium {
    background-color: #f37113; }
  .PigIconHigh {
    background-color: #da1e28; }
  .PigIconLowPulse {
    background-color: #0062ff;
    box-shadow: 0 0 0 0 #0062ff;
    animation: livePulseLow 2.5s infinite; }
  .PigIconMediumPulse {
    background-color: #f37113;
    box-shadow: 0 0 0 0 #f37113;
    animation: livePulseMedium 2.5s infinite; }
  .PigIconHighPulse {
    background-color: #da1e28;
    box-shadow: 0 0 0 0 #da1e28;
    animation: livePulseHigh 2.5s infinite; }

@keyframes livePulseLow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 98, 255, 0.5); }
  85% {
    box-shadow: 0 0 0 12px rgba(0, 98, 255, 0); }
  100% {
    box-shadow: 0 0 0 24px transparent; } }

@keyframes livePulseMedium {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 113, 19, 0.5); }
  85% {
    box-shadow: 0 0 0 12px rgba(243, 113, 19, 0); }
  100% {
    box-shadow: 0 0 0 24px transparent; } }

@keyframes livePulseHigh {
  0% {
    box-shadow: 0 0 0 0 rgba(218, 30, 40, 0.5); }
  85% {
    box-shadow: 0 0 0 12px rgba(218, 30, 40, 0); }
  100% {
    box-shadow: 0 0 0 24px transparent; } }

.Text {
  margin: 0;
  text-transform: capitalize; }

.LogCollapsed {
  position: relative;
  margin: 5px 0 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 65px; }
  .LogCollapsed::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 29px;
    top: -5px;
    width: 1px;
    height: 5px;
    background-color: #393939; }
  .LogCollapsed::after {
    content: "••••";
    position: absolute;
    transform: rotate(90deg);
    transform-origin: top left;
    z-index: 0;
    left: 39px;
    top: 5px;
    width: 1px;
    letter-spacing: 3px;
    font-size: 13px;
    color: #393939; }
  .LogCollapsed + .LogEntry::before {
    top: -5px; }

.ToggleCollapsedButton,
.ViewLessButton {
  color: #408bfd;
  padding: 0;
  line-height: 20px; }

.ViewLessButton {
  text-align: left;
  display: block;
  margin: 20px 0 20px 65px; }
  .ViewLessButton + .LogEntry::before {
    top: -60px; }

.LogList {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-bottom: 30px; }

.SmallText {
  display: block;
  width: 100%;
  color: #8d8d8d; }
