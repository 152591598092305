@import "../../styles/variables";
@import "../../styles/colors";

.Container {
  position: relative;
  width: 100%;

  @include breakpoint(large) {
    height: 100%;
    overflow-y: auto;
  }

  &Loading {
    //disable style lint for this line because the error is a false positive
    /* stylelint-disable function-calc-no-invalid */
    max-height: calc(100% - #{$mobile-header-breadcrumbs-height});
    /* stylelint-enable function-calc-no-invalid */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ListMapWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}

.List {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
}

.MapModalWrapper {
  position: absolute;
  background-color: $mine-shaft;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MapContainer {
  display: none;
}

@include breakpoint(large) {
  .HeaderWrapper {
    border-top: solid 0.25px $mine-shaft;
    border-bottom: solid 0.25px $mine-shaft;
  }

  .ListMapWrapper {
    overflow: hidden;
  }

  .List {
    display: block;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    min-width: 370px;
    max-width: 600px;
    border-right: solid 0.25px $mine-shaft;
  }

  .MapModalWrapper {
    position: relative; // EventModal needs relative parent
  }

  .MapContainer {
    display: flex;
    z-index: 1;
    position: relative; // EventModal needs relative parent
    background-color: $mine-shaft;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
