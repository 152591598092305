@import "../../styles/colors";
@import "../../styles/variables";

$track-color: $mine-shaft !default;
$limits-color: $dove-grey !default;
$range-color: $white !default;
$handle-color: $white !default;
$tooltip-color: $dark-mine-shaft !default;
$tooltip-text-color: $wild-sand !default;
$tooltip-focus-color: $link-primary-focus !default;
$text-color: $dove-grey !default;
$debug-handle-color: rgba($white, 0.25) !default;

$tooltip-spacing: 42px !default;
$slider-height: 12px !default;
$track-height: 4px !default;
$handle-width: 3px !default;
$handle-radius: 0 !default;
$padding: 5px !default;

.container {
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $tooltip-spacing;
}

.minValue,
.maxValue {
  color: $text-color;
  line-height: 1em;
}

.rangeInputs {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: $slider-height;
  margin: 0 ($padding + ($handle-width / 2));
}

.slider {
  position: relative;
  width: 100%;
  height: $track-height;
}

.sliderTrack,
.sliderRange,
.sliderLimits,
.sliderLeftValue,
.sliderRightValue {
  position: absolute;
}

.sliderTrack,
.sliderRange,
.sliderLimits {
  height: 100%;
}

.sliderTrack {
  background-color: $track-color;
  width: 100%;
  z-index: 1;
  border-radius: $track-height / 2;
}

.sliderLimits {
  background-color: $limits-color;
  width: 0%;
  z-index: 2;
  border-radius: $track-height / 2;
}

.sliderRange {
  background-color: $range-color;
  z-index: 3;
}

.handle {
  display: block;
  position: absolute;
  background-color: $handle-color;
  height: $slider-height;
  width: $handle-width;
  border-radius: $handle-radius;
  pointer-events: none;
  margin-top: $track-height / 2;
  z-index: 5;

  &.handleLeft {
    left: 0;
    transform: translate(-50%, -50%);
  }

  &.handleRight {
    right: 0;
    transform: translate(50%, -50%);
  }
}

.sliderLeftValue,
.sliderRightValue {
  color: $tooltip-text-color;
  background-color: $tooltip-color;
  padding: $padding;
  line-height: 1em;
  bottom: $slider-height + ($padding * 2);

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: #{$padding / 2} solid $tooltip-color;
    border-bottom-color: transparent;
    top: 100%;
  }
}

.sliderLeftValue {
  right: $handle-width;

  &::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
    right: 0;
  }
}

.sliderRightValue {
  left: $handle-width;

  &::after {
    border-right-color: transparent;
    left: 0;
  }
}

%disable-webkit-appearance {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.single {
  .handleRight {
    display: none;
  }
  .sliderLeftValue {
    right: auto;
    left: ($handle-width / 2);
    transform: translateX(-50%);

    &::after {
      content: none;
    }
  }
}

.thumb {
  @extend %disable-webkit-appearance;

  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  margin: 0;

  /* stylelint-disable selector-max-compound-selectors */
  &:nth-child(1):focus ~ .slider {
    .handleLeft {
      outline: 1px solid $tooltip-focus-color;
      z-index: 6;
    }
    .handleRight {
      z-index: 5;
    }
  }

  &:nth-child(2):focus ~ .slider {
    .handleLeft {
      z-index: 5;
    }
    .handleRight {
      outline: 1px solid $tooltip-focus-color;
      z-index: 6;
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
}

// this represents the real range slider
// handles, they will be invisible but
// big enough to grab with a finger on
// mobile. They're defined in a mixin
// because you can't combine selectors
// for range thumbs for some reason and
// I don't want to duplicate the code
@mixin thumb {
  background-color: transparent;
  border: 0;
  height: ($slider-height * 2) + $padding;
  width: ($slider-height * 2) + $padding;
  pointer-events: all;
  position: relative;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  /* Removing the default appearance */
  @extend %disable-webkit-appearance;
  @include thumb;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  @include thumb;
}

.container[data-debug] {
  .thumb::-webkit-slider-thumb {
    background-color: $debug-handle-color;
  }
  .thumb::-moz-range-thumb {
    background-color: $debug-handle-color;
  }
}

.thumbLeft {
  z-index: 5;
}

.thumbRight {
  z-index: 6;
}
