@import "../../styles/colors";
@import "../../styles/variables";

.DropdownWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Dropdown {
  position: absolute;
  background-color: $dark-mine-shaft;
  z-index: 110; //Must be greater than EventDetails modal z-index.
  list-style-type: none;
  padding: 0;
  top: calc(100% - 1px);
  margin: 0;
  min-width: 250px;

  @include breakpoint(large) {
    max-height: 460px;
    overflow-y: auto;
  }

  &Open {
    border: 1px solid $emperor;
  }

  &:hover {
    border-color: $gray;
  }
}

.DropdownButtonWrapper:hover ~ .Dropdown {
  border-color: $gray;
}

.Buttons {
  width: 100%;
  padding: $x-small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TextLink {
  padding: 0 $x-small;
}

.Label {
  display: block;
  margin-bottom: 8px;
}
