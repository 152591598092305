@import "../../../styles/variables";

.DeleteModal {
  width: calc(100% - 70px);
  margin: 0 auto;
  padding: 30px 18px;
  top: 85px;
}

.ModalTitle {
  margin-bottom: 20px;
}

.Confirm {
  margin-bottom: 25px;
}

.Text {
  margin: 3px 0 0;
}

.Buttons {
  display: flex;
}

.Button {
  flex: 1;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
}

.Alert {
  margin-bottom: 20px;
}

@include breakpoint(medium) {
  .DeleteModal {
    max-width: 565px;
  }
}

@include breakpoint(large) {
  .DeleteModal {
    padding: 30px;
    top: 255px;
    margin-bottom: 30px;
  }

  .Buttons {
    justify-content: flex-end;
  }

  .Button {
    flex: none;
  }
}
