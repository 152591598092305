@import "../../styles/variables";
@import "../../styles/colors";

/* stylelint-disable */

.js-plotly-plot {
  .plotly {
    .modebar-container {
      top: -66px !important;
    }

    .modebar {
      right: $xx-large !important;
    }

    .modebar,
    .modebar-group {
      background-color: transparent !important;
      height: auto;
    }

    .modebar-group:empty {
      display: none;
    }

    .modebar-btn {
      background-color: #262626;
      padding: 8px !important;
      // border: 1px solid #525252;
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }

      &:hover,
      &.active {
        background-color: #525252;
      }
    }

    .line {
      &:first-child {
        fill: $silver-chalice;
      }
      &:last-child {
        fill: $dove-grey;
      }
    }

    .hovertext {
      // cSpell:disable-line
      .line {
        &:last-child {
          fill: $silver-chalice;
        }
      }
    }
  }
}

/* stylelint-enable */
