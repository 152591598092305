@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/typography";

.Label {
  margin: 0 0 8px;
}

.DropdownWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.DropdownButton {
  @include body2();
  @include cubic-transition(border-color);

  border: 1px solid $emperor;
  padding: 0 40px 0 $x-small;
  background: $dark-mine-shaft;
  color: $white;
  text-transform: capitalize;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 40px;
  position: relative;

  &:hover,
  &:focus {
    border: 1px solid $gray;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid $emperor;
    color: $dove-grey;
  }

  &Selected {
    background-color: $mine-shaft;
  }

  &Small {
    height: 32px;
    padding: 0 40px 0 $x-small;
  }
}

@include breakpoint(large) {
  .DropdownButton {
    min-width: 120px;
    max-width: 100%;
  }
}

.Icon {
  display: flex;
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;
  position: absolute;
  top: $x-small;
  right: $x-small;

  &Open {
    transform: rotateX(180deg);
  }

  &Small {
    top: $xx-small;
  }
}

.Dropdown {
  position: absolute;
  background-color: $dark-mine-shaft;
  z-index: 110; //Must be greater than EventDetails modal z-index.
  list-style-type: none;
  padding: 0;
  top: calc(100% - 1px);
  left: 0;
  margin: 0;
  min-width: 100%;

  &NoLabel {
    top: 39px;
    &Small {
      top: 31px;
    }
  }

  &Open {
    border: 1px solid $emperor;
  }

  &:hover {
    border-color: $gray;
  }
}

.DropdownItem {
  @include body2();
  @include cubic-transition(background-color);

  padding: $x-small;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-bottom: 1px solid $mine-shaft;

  &:last-child {
    border-bottom: 0;
  }

  &Selected {
    background-color: $blue-ribbon;
  }

  &Small {
    height: 32px;
    padding: $xx-small;
    padding-left: $x-small;
  }
}

.DropdownButton:hover ~ .Dropdown {
  border-color: $gray;
}

.DropdownMainContent {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  align-items: center;
}

.DropdownLabel {
  white-space: nowrap;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &WithAltText {
    margin-right: 40px;
  }
}

.DropdownAltText {
  white-space: nowrap;
}

.IconWrapper {
  margin-right: 10px;
  display: flex;
}

.AltTextWrapper {
  display: flex;
  margin-left: auto;
}
