@import "../../styles/variables";
@import "../../styles/colors";

.Wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: $x-large $medium;
  flex: 1 1 350px;
  background-color: $cod-gray;
}

.LogForm {
  display: flex;
  flex-flow: row;
  margin-bottom: 10px;
}

.LogLabel {
  position: relative;
  flex: 1 0 auto;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    top: 30px;
    right: 0;
    height: 3px;
    width: 100%;
    background-color: $gray;
  }
}

.SendButton {
  margin: 28px 0 0 $x-small;
}

.LogFade {
  position: relative;
  height: 100%;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    height: 10px;
    z-index: 10;
    background-image: linear-gradient(to top, rgba($cod-gray, 0), $cod-gray);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 20px;
    height: 10px;
    z-index: 10;
    background-image: linear-gradient(to bottom, rgba($cod-gray, 0), $cod-gray);
  }
}

.LogScroll {
  height: 100%;
  overflow-y: auto;
  margin: 0;
}

.LogList {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 5px 20px 5px 0;
}

.LogEntry {
  display: flex;
  flex-flow: column;
  position: relative;
  padding-left: 30px;
  padding-bottom: 10px;

  // Timeline
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 9px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: $mine-shaft;
  }

  // No Timeline above icon for first entry
  &:first-child {
    &::before {
      top: 10px;
    }
  }

  // No Timeline below icon for last entry
  &:last-child {
    &::before {
      height: 10px;
    }
  }

  // No Timeline shown for only entry
  &:only-child {
    &::before {
      display: none;
    }
  }

  &Icon {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 11px;
    height: 11px;
    background-color: $gray;
    border-radius: 50%;
    border: solid 2px $mine-shaft;

    &System {
      background-color: $gray;
    }

    &Low {
      background-color: $blue-ribbon;
    }

    &Medium {
      background-color: $christine;
    }

    &High {
      background-color: $alizarin-crimson;
    }

    &Comment {
      left: 0;
      top: 0;
      color: $dark-mine-shaft;
      background-color: $white;
      width: 19px;
      height: 19px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.NextLine {
  color: $gray;
  transform: translate(0, -4px);
}

@include breakpoint(medium) {
  .Wrapper {
    height: 215px;
    padding: 20px 20px 5px 30px;
  }
}
