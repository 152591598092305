@import "../../styles/variables";
@import "../../styles/colors";

.Container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 1fr;
}

.HeaderWrapper {
  width: 100%;
  height: auto;
  border-style: none;
}

.FilterContentWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.Filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
}

.ContentModalWrapper {
  position: absolute;
  background-color: $almost-black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.ContentContainer {
  display: none;
  flex-flow: column;
  z-index: 1;
  position: relative; // EventModal needs relative parent
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.MobileFocusContent {
  .Filter {
    display: none;
  }
  .ContentContainer {
    display: flex;
  }
}

.FiltersPanel {
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: flex-start;
  min-height: 100%;
  z-index: 1;
}

@include breakpoint(large) {
  .HeaderWrapper {
    border-top: solid 0.25px $mine-shaft;
    border-bottom: solid 0.25px $mine-shaft;
  }

  .FilterContentWrapper {
    overflow: hidden;
  }

  .Filter {
    display: flex;
    position: relative;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    min-width: 370px;
    max-width: 600px;
    border-right: solid 0.25px $mine-shaft;
  }

  .ContentModalWrapper {
    position: relative; // EventModal needs relative parent
    overflow-y: auto;
  }

  .ContentContainer {
    display: flex;
  }

  .MobileFocusContent {
    .Filter {
      display: flex;
    }
    .ContentContainer {
      display: flex;
    }
  }
}
