@import "../../styles/colors";

.Page {
  position: relative;
  display: flex;
  background-color: $cod-gray;
}

.Screen {
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
}
