@import "../../../styles/colors";
@import "../../../styles/variables";

.Icon {
  stroke: $gray;
  fill: $cod-gray;
  color: $white;

  &Button {
    cursor: pointer;
    color: transparent; // Hide cluster icon focus ring

    &:focus {
      outline: none;
      color: $white; // Show cluster icon focus ring
    }
  }

  &Circle {
    stroke: $gray;
    fill: $cod-gray;
    color: $white;
  }

  &Count {
    stroke: $white;
    fill: $white;
    stroke-width: 1px;

    &Open {
      font-size: 0.8125rem; // 13px
      stroke-width: 1px;
    }
  }
}

.TextNode {
  fill: $gray;
  stroke-width: 1px;
  text-transform: capitalize;
  font-size: 0.625rem; // 10px
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.FadeOut {
  opacity: 0;
}
