.Spinner {
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: none;

  &Visible {
    display: flex;
    flex-direction: row;
    opacity: 1;
    width: 100%;
    height: 100%;
    padding: 50px;
  }
}
