@import "../../../styles/variables";
@import "../../../styles/colors";

.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px $mine-shaft;
}

.Icon {
  display: flex;
}

.Type {
  margin: 0 0 0 $x-small;
  text-transform: capitalize;
  color: $white;
}

.Id {
  margin: 0 0 0 34px;
  color: $white;
}

.Button {
  &:not(:last-child) {
    margin-right: 10px;
  }

  &Group {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 10px;
  }
  &Share {
    display: none;
  }
}

.TitleWrapper {
  display: flex;
  flex-wrap: wrap;

  .Type,
  .Id {
    align-self: baseline;
  }
}

@include breakpoint(large) {
  .Header {
    display: flex;
    padding: $x-small 0;
  }

  .EventId {
    margin-left: $x-small;
  }

  .TitleGroup {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
  }

  .Icon {
    margin-left: 5px;
  }

  .Id {
    margin-left: $x-small;
    display: inline;
  }
  .Button {
    &Group {
      padding: 0;
    }
    &Share {
      display: flex;
    }
  }

  .Type {
    margin-left: $x-small;
  }
}
