@import "../../../styles/colors";

.SearchRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;

  /* stylelint-disable-next-line selector-max-universal */
  > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.SearchRow > :first-child {
  margin-left: 0;
}

.SearchRow > :last-child {
  margin-right: 0;
}

.KPRangeSlider {
  margin-bottom: 28px;
}

.KPTextInputSeparator {
  border: solid 1px $mine-shaft;
  width: 8px;
  height: 1px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
}

.searchButton {
  min-width: 90px;
}

.FilterText {
  margin: 0 0 5px;
  max-width: 280px;
}
