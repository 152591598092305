@import "../../../styles/colors";
@import "../../../styles/variables";

.Wrapper {
  flex-direction: row;
  align-items: center;
  padding: 20px;
  display: none;
}

.FilterWrapper {
  margin-right: 12px;
  position: relative;
}

.Icon {
  margin-left: 20px;
}

.Filter {
  position: absolute;
  background-color: $mine-shaft;
  top: 50px;
  z-index: 110; //Must be greater than EventDetails modal z-index.
  padding: 22px 30px;
  display: none;

  &Open {
    display: block;
    min-width: 278px;
  }
}

.Buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 27px;
}

@include breakpoint(large) {
  .Wrapper {
    display: flex;
    flex: 0 auto;
    background-color: $dark-mine-shaft;

    > :not(:last-child) {
      margin-right: $small;
    }
  }

  .ResultsCount {
    padding-right: 16px;
    position: relative;
    bottom: -13px;
  }

  .ResponsiveGap {
    flex-grow: 1;
  }
}
