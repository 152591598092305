@import "../../../styles/colors";
@import "../../../styles/variables";

.Button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  background-color: $dark-mine-shaft;
  border: solid 1px $emperor;
  color: $white;
  margin: 0;
  cursor: pointer;
  transition: background-color ease 300ms;
  text-align: center;
  padding: 6px 10px;
}

.Reset {
  position: absolute;
  left: 20px;
  top: 20px;
  visibility: hidden;

  &Show {
    visibility: visible;
  }
}

.SearchButton {
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  height: 37px;

  &Loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark-mine-shaft;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Text {
  padding: 0;
  margin-right: 25px;
}
