@import "../../styles/colors";
@import "../../styles/variables";

.SidebarWrapper {
  z-index: 100;
  position: fixed;
  background-color: $dark-mine-shaft;
  width: 100%;
  height: $mobile-footer-height;
  left: 0;
  right: 0;
  bottom: 0;
}

.Sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background-color: $dark-mine-shaft;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: auto;
}

.Logo {
  display: none;
  color: $blue-ribbon;
}

.Menu {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;

  &Collapsible {
    z-index: 99;
    background-color: $dark-mine-shaft;
    flex: 1 0 auto;
    position: absolute;
    display: flex;
    flex-flow: column;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s ease;
    transform: translate(0, 100%);

    &Open {
      transform: translate(0, -69px);
    }
  }
}

.Link {
  z-index: 100;
  background-color: $dark-mine-shaft;
  display: flex;
  position: relative;
  height: auto;
  padding: 16px 10px 27px;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  color: #8a8d91;
  text-align: left;
  text-decoration: none;
  transition: 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    height: 5px;
    width: 100%;
    border-top: 4px solid $blue-ribbon;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s ease;
    opacity: 0;
  }

  &Icon {
    position: relative;

    &Count {
      position: absolute;
      top: -12px;
      right: -27px;
      border: solid 3px $dark-mine-shaft;
    }
  }

  &Selected {
    color: $white;
    background-color: $mine-shaft;

    .LinkIconCount {
      border-color: $mine-shaft;
    }

    &::before {
      opacity: 1;
    }
  }
}

.LinkText {
  display: none;
}

.Footer {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  border-bottom: solid 0.5px $dove-grey;

  &Account {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    height: 85px;
    overflow-x: hidden;
    padding: 26px;

    > .LinkIcon {
      min-width: 42px;
      margin-right: 26px;
    }
  }

  &Support {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    border-top: solid 0.5px $mine-shaft;
    height: 115px;
    overflow-x: hidden;
    padding: 21px 16px 26px 26px;

    > .LinkIcon {
      min-width: 42px;
      margin-right: 26px;
    }
  }
}

.Contact {
  color: $silver-chalice;
}

// Desktop Styles
$desktop-max-width: 260px;

@include breakpoint(large) {
  .SidebarWrapper {
    position: relative;
    background-color: $dark-mine-shaft;
    width: 78px;
    left: 0;
    bottom: 0;
    height: 100vh;
  }

  .Sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    width: 78px;
    overflow-x: hidden;
    z-index: 100;
    background-color: $dark-mine-shaft;
    transition: width 0.3s ease-in-out;
    border-right: $emperor 1px solid;

    &:hover {
      transition-delay: 0.25s;
      width: $desktop-max-width;
    }
  }

  .Header {
    position: relative;
    width: 100%;
    height: $desktop-header-height;
    padding: 18px 0 26px 26px;
    align-items: center;
    justify-content: flex-start;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 15px;
      top: 0;
      right: 0%;
      bottom: 0;
      background-color: $dark-mine-shaft;
    }
  }

  .Logo {
    display: flex;
  }

  .Menu {
    position: relative;
    flex: 0 0 auto;
    flex-flow: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;

    &Collapsible {
      background-color: transparent;
      position: relative;
      display: flex;
      flex: 1 0 auto;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      top: 0;
      transform: translate(0, 0);
    }

    &Secondary {
      display: flex;
      flex: 1 0 auto;
      flex-flow: column;
      width: $desktop-max-width;
    }
  }

  .Link {
    display: flex;
    position: relative;
    width: $desktop-max-width;
    height: 54px;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 auto;
    padding: 16px 16px 16px 26px;
    color: $gray;
    text-align: left;
    text-decoration: none;
    transition: 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 5px;
      border-top-style: none;
      border-left: 4px solid $blue-ribbon;
      top: 0;
      left: 0;
      bottom: 0;
      transition: 0.3s ease;
      opacity: 0;
    }

    &Icon {
      min-width: 42px;
      margin-right: 26px;

      &Count {
        top: -16px;
        right: -6px;
      }
    }

    &Selected {
      color: $white;
      background-color: $mine-shaft;

      &::before {
        opacity: 1;
      }
    }
  }

  .LinkText {
    display: flex;
  }

  .Footer {
    border-bottom-style: none;
    &Account {
      width: $desktop-max-width;
    }

    &Support {
      width: $desktop-max-width;
    }
  }
}
