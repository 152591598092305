@import "../../styles/variables";
@import "../../styles/colors";

.Container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* stylelint-disable function-calc-no-invalid */
  min-height: calc(100% - #{$mobile-footer-height});
  /* stylelint-enable function-calc-no-invalid */
  @include breakpoint(medium) {
    min-height: 100%;
  }
}

.HeaderWrapper {
  width: 100%;
  height: auto;
  border-style: none;
}

.ListMapWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  @include breakpoint(medium) {
    flex-grow: initial;
  }

  @include breakpoint(large) {
    // stylelint doesn't like calc but we need it so...
    // stylelint-disable-next-line function-calc-no-invalid
    height: calc(100% - #{$tabs-height-desktop});
  }
}

.List {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: none;
  padding-bottom: 80px;
  padding-top: $x-small;
}

.MapModalWrapper {
  position: absolute;
  background-color: $mine-shaft;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MapContainer {
  display: none;
}

.PaginationHeading {
  display: block;
  margin-bottom: $x-small;
  padding: $x-small $large;
}

@include breakpoint(large) {
  .Container {
    overflow-y: auto;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
  }

  .HeaderWrapper {
    border-top: solid 0.25px $mine-shaft;
    border-bottom: solid 0.25px $mine-shaft;
  }

  .ListMapWrapper {
    overflow: hidden;
  }

  .List {
    display: block;
    flex: 1 0 25%;
    overflow-y: auto;
    margin: 0;
    padding-top: $x-small;
    min-width: 390px;
    max-width: 600px;
    border-right: solid 0.25px $mine-shaft;
  }

  .MapModalWrapper {
    position: relative; // EventModal needs relative parent
  }

  .MapContainer {
    display: flex;
    z-index: 1;
    position: relative; // EventModal needs relative parent
    background-color: $mine-shaft;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
