@import "../../styles/colors";
@import "../../styles/variables";

.Section {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  &:first-child {
    border-bottom: solid 1px $mine-shaft;
  }

  &Last {
    border-bottom-style: none;
  }
}

.Title {
  width: 100%;
  padding: $large $medium $medium;
  font-size: 16px;
}

@include breakpoint(medium) {
  .Title {
    font-size: 14px;
    padding: $x-large $medium $medium $xx-large;
  }
}
