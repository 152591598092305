@import "../../styles/colors";
@import "../../styles/variables";

.Pagination {
  background-color: $cod-gray;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Buttons {
  height: 24px;
}

.Spacer {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}

.Disabled {
  opacity: 0.5;
}

.Button {
  background-color: transparent;
  border: 0;
  color: $white;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;

  &Icon {
    background-color: transparent;
    border: 0;
    height: 24px;
    width: 24px;
    padding: 0;
    margin-right: 10px;
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }

  &Text {
    padding: 0;
    margin: 0;
  }

  &Current {
    background-color: $white;
    color: $cod-gray;
  }
}
