@import "../../styles/variables";
@import "../../styles/colors";

$tabs-height-mobile: 52px;

.TabWrapper {
  position: relative;
  width: 100%;
  /* stylelint-disable function-calc-no-invalid */
  height: calc(100% - #{$mobile-header-height} - #{$mobile-footer-height});
  /* stylelint-enable function-calc-no-invalid */

  @include breakpoint(large) {
    height: 100%;
    overflow: hidden;
  }
}

.TabbedContent {
  width: 100%;
  background-color: $dark-mine-shaft;
  display: flex;
}

.Tab {
  display: none;
}

.TabButton {
  position: relative;
  height: $tabs-height-desktop;
  margin-bottom: -1px;
  background-color: $dark-mine-shaft;
  font-weight: $font-semibold;
  font-size: 12px;
  line-height: 24px;
  padding: $small $medium;
  border: 0;
  color: $white;
  flex-basis: 50%;
  text-align: center;
  border-bottom: solid 1px $mine-shaft;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include breakpoint(large) {
    border-bottom: 0;
    flex-basis: auto;
    font-size: 14px;
    min-width: 195px;
  }
}

.TabButtonSelected {
  border-bottom: 0.25em solid $blue-ribbon;
  z-index: 1;
  background-color: $mine-shaft;
  overflow: visible;
  width: auto;
}

.TabPanel {
  display: none;
}

.TabPanelSelected {
  display: block;
  height: 100%;
  width: 100%;
}

.Tabs {
  display: flex;
  width: 100%;
}
