@import "../../../styles/colors";
@import "../../../styles/variables";

.Header {
  display: flex;
  flex-flow: column;
  color: $white;
  width: 100%;
}

.TitleGroup {
  align-items: baseline;
  border-bottom: 1px solid $emperor;
  padding-bottom: $small;
}

.InfoGroup {
  padding-top: $small;
}

.Type {
  margin: 0 10px 0 0;
  text-transform: capitalize;
  &Dark {
    color: $gray;
    text-transform: capitalize;
  }
}

.Card {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  padding: $medium $small $medium $medium;
  margin-bottom: $x-small;
  border: solid 0.25px $mine-shaft;
  border-left-style: none;
  background-color: $dark-mine-shaft;
  text-align: unset;
  transition: background-color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    top: -0.25px;
    left: 0;
    bottom: -0.25px;
    width: 4px;
    transition: width 0.3s ease;
    background-color: $blue-ribbon;
  }

  &:focus {
    border-color: $white;
    outline: none;
  }

  &:hover {
    background-color: $almost-black;
  }

  &Medium {
    color: $christine;

    &::after {
      background-color: $christine;
    }
  }

  &High {
    color: $alizarin-crimson;

    &::after {
      background-color: $alizarin-crimson;
    }
  }
}

.PigStatus {
  position: relative;
  padding-left: $small;
  margin: 0;
  margin-top: $xx-small;
  display: flex;
  justify-content: space-between;
  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    left: 0;
    top: calc(50% - 4px);
    background-color: $success;
  }
  &Red {
    &::before {
      background-color: $error;
    }
  }
  &Yellow {
    &::before {
      background-color: $christine;
    }
  }
}
// Desktop Styles
@include breakpoint(large) {
  .Card {
    padding: $medium $medium $medium $large;
  }
}

.CompletedCard {
  display: block;
  background-color: $dark-mine-shaft;
  padding: $large $medium;
  margin: 0 0 5px;
}

.InfoGroupWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .InfoGroup {
    padding: 0;
    flex-shrink: 1;
  }
  .Type {
    margin: 0;
  }
}

.IconContainer {
  margin: 0 30px;
}

.Updates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;

  .Type {
    margin: 0;
  }
}
