@import "../../styles/variables";
@import "../../styles/colors";

.Title {
  display: flex;
  padding: 20px 0;
  align-items: center;
  &WithLink {
    flex-direction: column;
    align-items: flex-start;
    font-weight: $font-semibold;
  }
}

.Text {
  margin-right: 11px;
  display: flex;
  align-items: center;

  .Text {
    margin-left: 11px;
  }
}

@include breakpoint(large) {
  .Title {
    padding: 0;
  }
}

.LinkIcon {
  transform: rotate(90deg);
  transform-origin: center;
  width: 16px;
  color: $silver;
  display: flex;
  align-items: center;
}

.BreadCrumb {
  display: flex;
  align-items: center;
  margin-bottom: $xx-small;
  font-weight: 600;
}

.LinkText {
  margin-left: 5px;
}
