@import "../../styles/variables";

.Empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.EmptyDesc {
  margin: 10px 0 20px;
  @include breakpoint(medium) {
    white-space: pre-line;
  }
}
