@import "../../../styles/variables";
@import "../../../styles/colors";

.LogForm {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: $large;
}

.InputWrapper {
  flex-grow: 1;
}

.SendButton {
  margin-left: 10px;
}

.LogInputText {
  margin: $medium 0 0;
}

.EmptyText {
  padding: 10px;
  background-color: $dark-mine-shaft;
  display: block;
  margin-top: 23px;
}

.ToggleButton {
  border: 0;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: $white;
  cursor: pointer;
}

.ButtonText {
  display: block;
  text-align: left;
}

.ToggleIcon {
  display: flex;
  margin-left: $x-small;
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;

  &Open {
    transform: rotateX(180deg);
  }
}

.Notes {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  text-align: left;

  &Open {
    visibility: visible;
    max-height: 100%;
    overflow-y: auto;
  }
}
