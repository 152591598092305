@import "../../styles/colors";
@import "../../styles/variables";

.ToolTip {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $cod-gray;
  padding: 10px;
  display: flex;
  flex-flow: column;

  &Type {
    text-transform: capitalize;
  }
}

.Icon {
  position: relative;
  text-transform: capitalize;
  padding-left: 15px;
  margin-top: 4px;
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 2px;
    width: 8px;
    height: 8px;
    background-color: $blue-ribbon;
    border-radius: 50%;
  }

  &Medium::before {
    background-color: $christine;
  }

  &High::before {
    background-color: $alizarin-crimson;
  }
}

.PigLocation {
  margin-top: 4px;
}
