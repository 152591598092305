@import "../../styles/colors";

.Alert {
  color: $error;
  border: 1px solid $error;
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 12px;
  margin-bottom: 9px;

  &Success {
    color: $success;
    border-color: $success;
  }

  &Text {
    margin-left: 12px;
  }
}
