@import "../../styles/colors";
@import "../../styles/variables";

.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  border-style: none;
  border: solid 1px $gray;
  transform: translate(40px, 0);
}

.gm-style .gm-style-iw-d::-webkit-scrollbar {
  display: none;
}

.gm-style .gm-style-iw-t::after {
  background: rgba(22, 22, 22, 1);
  transform: translate(32px, 26px) rotate(45deg);
  box-shadow: none;
  border-left: solid 1px $gray;
  border-bottom: solid 1px $gray;
}

/* stylelint-disable-next-line selector-max-type */
.gm-style-iw-c > button {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important; /* override inline display style */
}
